import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/SEO"
import ContactForm from "../components/ContactForm"
import CaseStudiesLoader from "../components/CaseStudiesLoader"
import HeroCaseStudiesArchive from "../components/HeroCaseStudiesArchive"

const CaseStudies = () => {

  const data = useStaticQuery(graphql`
    {
      page: mdx(fileAbsolutePath: { regex: "/content/case-studies/" }) {
        frontmatter {
          title
          path
          text
          pinToTop
          numPosts
        }
      }
      caseStudies: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/caseStudies/" } },
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              client
              path
              shortDescription
              heroBackgroundImage
              order
              imageDesktop {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              imageMobile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { title, text, pinToTop, numPosts } = data.page.frontmatter
  const caseStudies = data.caseStudies.edges

  const pinned = caseStudies.filter(caseStudy => {
    return caseStudy.node.frontmatter.client === pinToTop
  })

  return (
    <>
      <SEO title="Case Studies Archive" />
      <HeroCaseStudiesArchive
        title={ title }
        subtitle={ text }
        featured={ pinned }
      />
      <CaseStudiesLoader numPosts={ numPosts } featured={ pinToTop } caseStudies={ caseStudies } />
      <ContactForm />
    </>
  )
}

export default CaseStudies
