import React from "react"
import CaseStudyArchiveCard from "./CaseStudyArchiveCard"
import safeGetImage from "../utils/safeGetImage"

export default function CaseStudiesList({ caseStudies, skip, limit }) {
  const allCaseStudies = caseStudies
  //   get relevant case studies
  const selectedCaseStudies = allCaseStudies.slice(skip, limit)
  return (
    <section className="case-studies__archive">
      {selectedCaseStudies.map((caseStudy, i) => {
        const {
          title,
          client,
          shortDescription,
          heroBackgroundImage,
          imageDesktop,
          imageMobile,
          path,
        } = caseStudy.node.frontmatter

        const imagePosition = i % 2 === 0 ? "left" : "right"
        return (
          <CaseStudyArchiveCard
            key={caseStudy.node.id}
            client={client}
            subtitle={title}
            blurb={shortDescription}
            backgroundImage={`url(${safeGetImage(heroBackgroundImage)})`}
            path={path}
            imageMobile={imageMobile}
            imageDesktop={imageDesktop}
            imagePosition={imagePosition}
          ></CaseStudyArchiveCard>
        )
      })}
    </section>
  )
}
