import React from "react"
import CaseStudiesList from "../components/CaseStudiesList"
import { useState } from "react"

export default function CaseStudiesLoader({ numPosts, featured, caseStudies }) {
  // remove featured case study from list
  let notFeatured = caseStudies.filter(caseStudy => {
    return caseStudy.node.frontmatter.client !== featured
  })
  // keep track of the number of CaseStudiesList components
  let [numChildren, setNumChildren] = useState(1)
  let [limit, setLimit] = useState(numPosts) // set the number of posts to show
  // update state when Load More is clicked
  const loadMore = () => {
    setNumChildren(numChildren + 1)
    setLimit(limit + numPosts)
  }
  const caseStudiesLoaded = []
  for (var i = 0; i < numChildren; i += 1) {
    caseStudiesLoaded.push(
      <CaseStudiesList
        caseStudies={notFeatured}
        skip={i * numPosts}
        limit={limit}
        key={i}
        number={i}
      />
    )
  }
  return (
    <>
      {caseStudiesLoaded[0]}
      {notFeatured.length > limit && (
        <div className="load-more">
          <button
            data-text="Load more"
            className="button button-primary"
            onClick={loadMore}
          >
            Load more
          </button>
        </div>
      )}
    </>
  )
}
