import React from "react"
import PropTypes from "prop-types"

import Panel from "./core/Panel"
import TextBlock from "./TextBlock"
import DesktopImageContainer from "../components/DesktopImageContainer"
import MobileImageContainer from "../components/MobileImageContainer"
import safeGetImage from "../utils/safeGetImage"

const HeroCaseStudiesArchive = ({ title, subtitle, featured }) => {
  
  const {
    heroBackgroundImage,
    imageMobile,
    imageDesktop,
    client,
    shortDescription,
    path,
  } = featured[0].node.frontmatter

  //  get correct image format to decide on frame
  const image = imageMobile
    ? {
        src: imageMobile,
        title: client,
      }
    : {
        src: imageDesktop,
        title: client,
      }
  const imgType = imageMobile ? "mobile" : "desktop"
  return (
    <Panel
      className="case-study-archive__card case-study-archive__hero"
      color="purple"
      backgroundImage={`url(${safeGetImage(heroBackgroundImage)})`}
    >
      <div
        className={`case-study-archive__card__contents--right mobile-screen`}
      >
        <div className={`case-study-archive__image ${imgType}`}>
          {imageMobile ? (
            <MobileImageContainer image={image} />
          ) : (
            <DesktopImageContainer image={image} />
          )}
        </div>
        <div className={`case-study-archive__text ${imgType}`}>
          <TextBlock
            className="text-card__hero"
            title={title}
            text={subtitle}
          />
          <TextBlock
            title={client}
            button={{
              link: path,
              text: "Read More",
              colour: "primary",
              type: "link",
              gatsbyLink: true
            }}
          >{shortDescription}</TextBlock>
        </div>
      </div>
    </Panel>
  )
}

HeroCaseStudiesArchive.propTypes = {
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default HeroCaseStudiesArchive
